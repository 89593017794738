define("additive-account/components/aa-settings/integrations/add-dialog", ["exports", "@ember/component", "additive-account/config/environment", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types", "@ember/service", "ember-concurrency", "@ember/template-factory"], function (_exports, _component, _environment, _component2, _tracking, _object, _emberArgTypes, _propTypes, _service, _emberConcurrency, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t "components.aaSettings.integrations.addDialog.title"}}
    @description={{t "components.aaSettings.integrations.addDialog.description"}}
    @onClose={{this.close}}
    as |modal|
  >
    <modal.header />
  
    <modal.content as |content|>
      <content.body>
        <div class="font-md pb2">
          {{t "components.aaSettings.integrations.addDialog.description"}}
        </div>
  
        <UiSelect
          @label={{t "components.aaSettings.integrations.addDialog.select.label"}}
          @placeholder={{t "components.aaSettings.integrations.addDialog.select.placeholder"}}
          @options={{this._integrations}}
          @selected={{if
            this.selectedIntegration
            (t (concat "components.aaSettings.integrations.pmsNames." this.selectedIntegration))
          }}
          @onChange={{fn (mut this.selectedIntegration)}}
          as |option integration|
        >
          <option.item @value={{integration.value}}>
            {{t (concat "components.aaSettings.integrations.pmsNames." integration.value)}}
          </option.item>
        </UiSelect>
      </content.body>
    </modal.content>
  
    <modal.footer>
      <UiButton @label={{t "global.actions.save"}} @onClick={{perform this._save}} />
    </modal.footer>
  </UiModal>
  */
  {
    "id": "vPu0M87d",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@description\",\"@onClose\"],[[28,[37,1],[\"components.aaSettings.integrations.addDialog.title\"],null],[28,[37,1],[\"components.aaSettings.integrations.addDialog.description\"],null],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"font-md pb2\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"components.aaSettings.integrations.addDialog.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,2],null,[[\"@label\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[[28,[37,1],[\"components.aaSettings.integrations.addDialog.select.label\"],null],[28,[37,1],[\"components.aaSettings.integrations.addDialog.select.placeholder\"],null],[30,0,[\"_integrations\"]],[52,[30,0,[\"selectedIntegration\"]],[28,[37,1],[[28,[37,4],[\"components.aaSettings.integrations.pmsNames.\",[30,0,[\"selectedIntegration\"]]],null]],null]],[28,[37,5],[[28,[37,6],[[30,0,[\"selectedIntegration\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"item\"]],null,[[\"@value\"],[[30,4,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[[28,[37,4],[\"components.aaSettings.integrations.pmsNames.\",[30,4,[\"value\"]]],null]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[3,4]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@label\",\"@onClick\"],[[28,[37,1],[\"global.actions.save\"],null],[28,[37,8],[[30,0,[\"_save\"]]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\",\"option\",\"integration\"],false,[\"ui-modal\",\"t\",\"ui-select\",\"if\",\"concat\",\"fn\",\"mut\",\"ui-button\",\"perform\"]]",
    "moduleName": "additive-account/components/aa-settings/integrations/add-dialog.hbs",
    "isStrictMode": false
  });
  let AaSettingsIntegrationsAddDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.func), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberConcurrency.task)(function* () {
    const tasks = [];
    const integrationEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/settings/pms`;
    try {
      const saveTask = this.authenticatedFetch.fetch(integrationEndpoint, {
        method: 'POST'
      }, {
        pms: {
          type: this.selectedIntegration
        }
      });
      tasks.push(saveTask);
      tasks.push((0, _emberConcurrency.timeout)(250));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        throw new Error(`[settings-integration] Could not save integration.`);
      }
      const {
        pms
      } = yield response.json();
      this.uiToast.showToast({
        title: this.intl.t('global.toasts.success.changes.title'),
        description: this.intl.t('global.toasts.success.changes.description'),
        type: 'success'
      });
      this.onAdd(pms);
      this.close();
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toasts.error.title'),
        description: this.intl.t('global.toasts.error.description')
      });
    }
  }), _class = class AaSettingsIntegrationsAddDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "selectedIntegration", _descriptor5, this);
      /* Available integrations */
      _defineProperty(this, "_integrations", [{
        value: 'aida'
      }, {
        value: 'apaleo'
      }, {
        value: 'asa'
      }, {
        value: 'casablanca'
      }, {
        value: 'elite'
      }, {
        value: 'fidelio'
      }, {
        value: 'gastrodat'
      }, {
        value: 'guestline'
      }, {
        value: 'hotel_felix'
      }, {
        value: 'mews'
      }, {
        value: 'protel'
      }, {
        value: 'si_hot'
      }, {
        value: 'si_tec'
      }, {
        value: 'win_hotel'
      }, {
        value: 'opera'
      }]);
      _initializerDefineProperty(this, "onAdd", _descriptor6, this);
      _initializerDefineProperty(this, "onClose", _descriptor7, this);
      _initializerDefineProperty(this, "_save", _descriptor8, this);
    }
    get organizationId() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }
    close() {
      return this.onClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedIntegration", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onAdd", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaSettingsIntegrationsAddDialog);
});